import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { GuardRoute } from '@clodeo-internal-finance/components/guard-route/guard-route.component';

const ListCustomerComponent = React.lazy(() => import('./features/customer/list'));
const CustomerDetailComponent = React.lazy(() => import('./features/customer/detail'));
const CustomerVerificationComponent = React.lazy(() => import('./features/customer/verification'));
const CustomerDetailVerificationComponent = React.lazy(() => import('./features/customer/verification/detail'));
const SalesLstComponent = React.lazy(() => import('./features/sales/list'));
const SalesCategoryListComponent = React.lazy(() => import('./features/sales-category/list'));
const SalesCompanyListComponent = React.lazy(() => import('./features/sales-company/list'));
const SalesCompanyDetailComponent = React.lazy(() => import('./features/sales-company/detail'));
const CustomerCategoryListComponent = React.lazy(() => import('./features/customer-category/list'));
const SalesTargetListComponent = React.lazy(() => import('./features/sales-target/list'));
const BankAccountVerificationComponent = React.lazy(() => import('./features/verification'));
const SettingMasterData = React.lazy(() => import('./features/setting'));
const HistoryComponent = React.lazy(() => import('./features/history'));
const HistoryCODFeeListComponent = React.lazy(() => import('./features/history-cod-fee/list'));
const CustomerUpdateVerificationComponent = React.lazy(() => import('./features/customer/update-verification'));
const SalesCommissionListComponent = React.lazy(() => import('./features/sales-commission/list'));
const SalesCommissionCreateComponent = React.lazy(() => import('./features/sales-commission/create'));
const SalesCommissionUpdateComponent = React.lazy(() => import('./features/sales-commission/update'));

export function SalesRouter() {
    const { path, url } = useRouteMatch();
    return (
        <Switch>
            {/* Customer Page */}
            <GuardRoute 
                path={`${url}/sales/customer`}
                exact
                meta={{
                    permissions: ['master-data.customer.view'],
                    module: ['master-data']
                }}
            >
                <ListCustomerComponent />
            </GuardRoute>
            <GuardRoute 
                path={`${url}/sales/customer/:id`}
                exact
                meta={{
                    permissions: ['master-data.customer.view'],
                    module: ['master-data']
                }}
            >
                <CustomerDetailComponent />
            </GuardRoute>

            {/* Sales Page */}
            <GuardRoute 
                path={`${url}/sales/sales`}
                exact
                meta={{
                    permissions: ['master-data.sales.view'],
                    module: ['master-data']
                }}
            >
                <SalesLstComponent />
            </GuardRoute>

            {/* Sales Caategory Page */}
            <GuardRoute 
                path={`${url}/sales/sales-category`}
                exact
                meta={{
                    permissions: ['master-data.sales-category.view'],
                    module: ['master-data']
                }}
            >
                <SalesCategoryListComponent />
            </GuardRoute>

            {/* Sales Company Page */}
            <GuardRoute 
                path={`${url}/sales/sales-company`}
                exact
                meta={{
                    permissions: ['master-data.sales-company.view'],
                    module: ['master-data']
                }}
            >
                <SalesCompanyListComponent />
            </GuardRoute>
            <GuardRoute 
                path={`${url}/sales/sales-company/:id`}
                exact
                meta={{
                    permissions: ['master-data.sales-company.view'],
                    module: ['master-data']
                }}
            >
                <SalesCompanyDetailComponent />
            </GuardRoute>

            {/* Customer Category Page */}
            <GuardRoute 
                path={`${url}/sales/customer-category`}
                exact
                meta={{
                    permissions: ['master-data.customer-category.view'],
                    module: ['master-data']
                }}
            >
                <CustomerCategoryListComponent />
            </GuardRoute>

            {/* Sales Target Page */}
            <GuardRoute 
                path={`${url}/sales/sales-target`}
                exact
                meta={{
                    permissions: ['master-data.sales-target.view'],
                    module: ['master-data']
                }}
            >
                <SalesTargetListComponent />
            </GuardRoute>

            {/* Bank Account Change Verification Page */}
            <GuardRoute 
                path={`${url}/sales/verification/:id`}
                exact
                meta={{
                    permissions: ['master-data.customer.view'],
                    module: ['master-data']
                }}
            >
                <BankAccountVerificationComponent />
            </GuardRoute>

            {/* Master Data Setting Page */}
            <GuardRoute 
                path={`${url}/sales/setting`}
                exact
                meta={{
                    permissions: ['setting.setting.view'],
                    module: ['master-data' && 'finance-apps-clodeo' && 'finance-apps-shipdeo']
                }}
            >
                <SettingMasterData />
            </GuardRoute>

            {/* Bank Account Verification List Page */}
            <GuardRoute 
                path={`${url}/sales/bank-account-verification`}
                exact
                meta={{
                    permissions: ['master-data.customer-verification.view'],
                    module: ['master-data']
                }}
            >
                <CustomerVerificationComponent />
            </GuardRoute>
            <GuardRoute 
                path={`${url}/sales/bank-account-verification/:id`}
                exact
                meta={{
                    permissions: ['master-data.customer-verification.view'],
                    module: ['master-data']
                }}
            >
                <CustomerDetailVerificationComponent />
            </GuardRoute>

            {/* Master Date Approval */}
            <GuardRoute 
                path={`${url}/sales/history-discount`}
                exact
                meta={{
                    permissions: ['master-data.approval-discount-history.view'],
                    module: ['master-data']
                }}
            >
                <HistoryComponent />
            </GuardRoute>

            {/* History COD Fee */}
            <GuardRoute 
                path={`${url}/sales/history-cod-fee`}
                exact
                meta={{
                    permissions: ['master-data.approval-cod-fee-history.view'],
                    module: ['master-data']
                }}
            >
                <HistoryCODFeeListComponent />
            </GuardRoute>

            <GuardRoute 
                path={`${url}/sales/customer/update-verification-data/:id`}
                exact
                meta={{
                    permissions: ['master-data.approval-cod-fee-history.view'],
                    module: ['master-data']
                }}
            >
                <CustomerUpdateVerificationComponent />
            </GuardRoute>

            {/* Sales Commission */}
            <GuardRoute 
                path={`${url}/sales/sales-commission/list`}
                exact
                meta={{
                    permissions: ['master-data.sales-commission.view'],
                    module: ['master-data']
                }}
            >
                <SalesCommissionListComponent />
            </GuardRoute>
            <GuardRoute 
                path={`${url}/sales/sales-commission/create`}
                exact
                meta={{
                    permissions: ['master-data.sales-commission.view'],
                    module: ['master-data']
                }}
            >
                <SalesCommissionCreateComponent />
            </GuardRoute>
            <GuardRoute 
                path={`${url}/sales/sales-commission/:id`}
                exact
                meta={{
                    permissions: ['master-data.sales-commission.view'],
                    module: ['master-data']
                }}
            >
                <SalesCommissionUpdateComponent />
            </GuardRoute>
        </Switch>
    )
}
