import React, { createContext } from 'react';
import {
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';

// import { MainLayoutComponent } from '../layout-component/main/main-layout.component';
import { IGuardRoute } from './guard-route';
import { AclService } from '@clodeo-internal-finance/core/service/auth/acl.service';
import { AuthenticationService } from '@clodeo-internal-finance/core/service/auth/authentication.service';

const authService: AuthenticationService = new AuthenticationService();
const aclService: AclService = new AclService();
const authContext = createContext(authService.user);

function useProvideAuth() {
  return authService.user;
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function GuardRoute(props: IGuardRoute) {
  const location = useLocation();
  const router = useHistory();

  // enable if using permission
  const pathName = location.pathname;
  const auth = authService.user;
  if (pathName === '/login' && auth) {
    router.push('/');
  }

  if (pathName === '/' && auth) {
    // router.push('/monitoring-awb');

    aclService.redirectAllowedMenu(router);

  } else if (props?.meta?.permissions) {
    const module = localStorage.getItem('appName');
    const isValidRoles = aclService.can([module], props.meta.permissions);

    if (!isValidRoles) {
      router.push(`/403`);
    }
  }

  return (
    <Route
      {...props}
      children={null}
      render={({ location }) =>
        auth ? (
          // <MainLayoutComponent title={props.title || ''} pathName={location.pathname}>
          props.children
          // </MainLayoutComponent>
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  );
}
