import { throwError } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';
import _ from 'lodash';
import { AxiosInstance, AxiosStatic } from 'axios';
// import { TemporaryFixService } from '../common/temporary-fix.service';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { environment } from '../../../../../apps/cod-web/src/environments/environment';
import { AuthRestService } from './auth-rest.service';
// const temporaryService: TemporaryFixService = new TemporaryFixService();
const authRestService = new AuthRestService(
  `${environment.ENDPOINTS.API_AUTH}`,
);
const notif = new Notifications();

export class MUser {
  accessToken: string;
  accessTokenExpiresAt: Date | string;
  client: {
    id: string;
    clientId: string;
    clientSecret: string;
    grants: string[];
  };
  user: {
    fullName: string;
    email: string;
    isVerified: boolean;
    createdAt: Date | string;
    updatedAt: Date | string;
    id: string;
  };
  userDomainId: string;
  tenantId: string;
  tenant: {
    _id: string;
    tenantOwnerUserId: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    __v: 0;
    id: string;
  };
  appId: string;
  app: {
    _id: string;
    name: string;
    appOwnerUserId: string;
    tenantId: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    __v: number;
  };
  roleNames: string[];
  userAccessPermissionKeys: any[];
  refreshToken: string;
  refreshTokenExpiresAt: Date | string;
  access_token: string;
}

let data: MUser;

// just for experimental, so this function need to improve
const refreshToken = () => {
  const payload = {
    grant_type: 'refresh_token',
    refresh_token: data.refreshToken,
    client_secret: 'QfsRBJq9HRknX9zC',
    client_id: 'clodeo-internal-finance-cod-web',
  };
  return authRestService.getToken(payload).pipe(
    tap((loginData: never) => {
      data = loginData;
      localStorage.setItem('currentUser', JSON.stringify(loginData));
      window.location.reload();
    }),
  );
};

export class AuthenticationService {
  clientId = 'clodeo-internal-finance-cod-web';
  constructor() {
    const currentUser = localStorage.getItem('currentUser');

    // checking if user manually insert the localstorage
    let user;
    try {
      user = JSON.parse(currentUser);
    } catch (e) {
      this.logout();
    }

    if (user && _.has(user, 'access_token')) {
      this.user = user as any;
    } else {
      this.user = null;
    }
  }

  set user(newData: MUser) {
    data = newData;
  }

  get user() {
    return data;
  }

  static axiosInterceptors(
    axios: AxiosInstance | AxiosStatic,
    interceptRequest = true,
    interceptResponse = true,
  ) {
    axios.interceptors.request.use((request) => {
      // request.headers.common['Timezone-Offset'] = _.get(data, 'timezone_offset') || (new Date).getTimezoneOffset();
      request.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
      request.headers.common['Accept'] = 'application/json, text/plain, */*';
      // if use multi language this config need to improve
      // request.headers.common['Accept-Language'] = 'id-ID';

      return request;
    });

    if (interceptRequest) {
      axios.interceptors.request.use((request) => {
        if (data) {
          request.headers.common[
            'Authorization'
          ] = `Bearer ${data.access_token}`;
        }
        return request;
      });
    }

    if (interceptResponse) {
      axios.interceptors.response.use(
        (response) => {
          // temporaryService.adressObjectNullHandler(response);

          return response;
        },
        async (error) => {
          const response = error.response;
          const errorCode = _.get(response, 'data.errors[0].error_code');
          if (
            response &&
            errorCode === 401 &&
            response.config &&
            response.config.headers &&
            response.config.headers['Authorization']
          ) {
            return refreshToken()
              .pipe(
                switchMap(function (accessToken) {
                  data = accessToken;
                  response.config.headers[
                    'Authorization'
                  ] = `Bearer ${accessToken}`;
                  return axios.request(response.config);
                }),
                catchError((err) => {
                  notif.show({
                    type: 'error',
                    title: 'Error',
                    description: 'Sesi telah berakhir, mohon login kembali',
                    useService: true,
                  });
                  data = null;
                  localStorage.removeItem('currentUser');
                  window.location.href = '/login';
                  return throwError(err);
                }),
              )
              .toPromise();
          }
          throw error;
        },
      );
    }
  }

  login(payload: any) {
    const newPayload = {
      ...payload,
      client_id: this.clientId,
      grant_type: 'password',
      client_secret: 'QfsRBJq9HRknX9zC',
    };

    return authRestService.getToken(newPayload).pipe(
      tap((loginData: any) => {
        this.user = loginData;
        localStorage.setItem('currentUser', JSON.stringify(loginData));
      }),
    );
  }

  logout() {
    data = null;
    localStorage.removeItem('currentUser');
    localStorage.removeItem('role');
    localStorage.removeItem('userData');
    localStorage.removeItem('platform');
    localStorage.removeItem('appName');
    window.location.href = '/login';
  }
}
