import React from 'react';

import { Switch, useRouteMatch } from 'react-router-dom';
import { GuardRoute } from '@clodeo-internal-finance/components/guard-route/guard-route.component';

const DataVerificationListComponent = React.lazy(() => import('./app/features/data-verification/list'));
const AffiliateReportListComponent = React.lazy(() => import('./app/features/affiliate-report/list'));
const DetailAffiliateReportComponent = React.lazy(() => import('./app/features/affiliate-report/detail'));
const PaymentComissionListComponent = React.lazy(() => import('./app/features/payment-comission/list'));
const VerifiactionListDetailComponent = React.lazy(() => import('./app/features/data-verification/detail'));
const PaymentComissionReferralListComponent = React.lazy(() => import('./app/features/payment-comission-referral/list'));

export function PortalReferralRouter() {
    const { path, url } = useRouteMatch();
    return (
        <Switch>
            {/* data verification */}
            <GuardRoute
                path={`${url}/portal-referral/data-verification/list`}
                exact
                meta={{
                    permissions: ['monitoring-cs.view'],
                    module: ['customer-service'],
                }}
            >
                <DataVerificationListComponent />
            </GuardRoute>
            <GuardRoute
                path={`${url}/portal-referral/data-verification/list/detail/:id`}
                exact
                meta={{
                    permissions: ['monitoring-cs.view'],
                    module: ['customer-service'],
                }}
            >
                <VerifiactionListDetailComponent />
            </GuardRoute>

            {/* affiliate report */}
            <GuardRoute
                path={`${url}/portal-referral/affiliate-report/list`}
                exact
                meta={{
                    permissions: ['monitoring-cs.view'],
                    module: ['customer-service'],
                }}
            >
                <AffiliateReportListComponent />
            </GuardRoute>

            <GuardRoute
                path={`${url}/portal-referral/affiliate-report/list/detail/:id`}
                exact
                meta={{
                    permissions: ['monitoring-cs.view'],
                    module: ['customer-service'],
                }}
            >
                <DetailAffiliateReportComponent />
            </GuardRoute>

            {/* payment comission */}
            <GuardRoute
                path={`${url}/portal-referral/payment-comission/list`}
                exact
                meta={{
                    permissions: ['monitoring-cs.view'],
                    module: ['customer-service'],
                }}
            >
                <PaymentComissionListComponent />
            </GuardRoute>

            <GuardRoute
                path={`${url}/portal-referral/referral-payment-comission/list`}
                exact
                meta={{
                    permissions: ['monitoring-cs.view'],
                    module: ['customer-service']
                }}
            >
                <PaymentComissionReferralListComponent />
            </GuardRoute>
        </Switch>
    );
}
