import React, { useEffect, useState } from 'react';

import { Menu } from 'antd';

import { AclService } from '@clodeo-internal-finance/core/service/auth/acl.service';
import { filter, pick, pull, remove } from 'lodash';
import { IMenu } from '../menu/menu';
import { useHistory, useLocation, Link } from 'react-router-dom';

import './menus.component.scss';

import eye from '../../../../../apps/cod-web/src/assets/img/icons/eye.svg';
import chat from '../../../../../apps/cod-web/src/assets/img/icons/chat-double.svg';
import circlesFour from '../../../../../apps/cod-web/src/assets/img/icons/circles-four.svg';
import fileText from '../../../../../apps/cod-web/src/assets/img/icons/file-text.svg';
import gear from '../../../../../apps/cod-web/src/assets/img/icons/gear.svg';
import money from '../../../../../apps/cod-web/src/assets/img/icons/money.svg';
import chatDots from '../../../../../apps/cod-web/src/assets/img/icons/chat-teardrop-dots.svg';
import circleHalf from '../../../../../apps/cod-web/src/assets/img/icons/circle-half.svg';

import { Divider } from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';

export const MenusLayoutComponent = () => {
  const [secMenus, setSecMenus] = useState<IMenu[]>([]);
  const [menus, setMenus] = useState<IMenu[]>([]);

  const { SubMenu } = Menu;
  const router = useHistory();

  const { pathname: current } = useLocation();

  const aclService: AclService = new AclService();
  const appName = localStorage.getItem('appName');

  useEffect(() => {
    const validMenus = filter(aclService.allowedMenuByModule(), function (o) {
      return !o.isSecMenu;
    });

    setMenus(validMenus);

    if (appName === 'setting') {
      const settingsMenus = filter(aclService.allowedMenuByModule(), 'isSecMenu').sort(
        (data) => data.secMenuPosition,
      );

      return setSecMenus(settingsMenus);
    } else {
      const settingsMenus = [];

      return setSecMenus(settingsMenus);
    }
  }, []);

  const icons = (iconName) => {
    switch (iconName) {
      case 'eye':
        return <img src={eye} alt="icon-eye" />;
      case 'chat':
        return <img src={chat} alt="icon-chat" />;
      case 'circles-four':
        return <img src={circlesFour} alt="icon-circles-four" />;
      case 'file-text':
        return <img src={fileText} alt="icon-file-text" />;
      case 'gear':
        return <img src={gear} alt="icon-gear" />;
      case 'money':
        return <img src={money} alt="icon-money" />;
      case 'chat-dots':
        return <img src={chatDots} alt="icon-chat-dots" />;
      case 'circle-half':
        return <img src={circleHalf} alt="icon-circle-half" />;
      default:
        return <div />;
    }
  };

  return (
    <Menu
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      theme="light"
      selectedKeys={[current.split('/').slice(2, 4).join('/')]}
    >
      {menus.map((menu: IMenu, i: number) => {
        return (
          <React.Fragment key={i}>
            {menu?.subs && menu?.subs?.length ? (
              <SubMenu
                className="custom-subs-menu"
                key={menu?.to}
                title={menu?.label}
                icon={icons(menu?.icon)}
              >
                {menu?.subs?.map((subMenu: IMenu, index: number) => (
                  <Menu.Item
                    key={subMenu?.to?.split('/').slice(2, 4).join('/')}
                    className={
                      index === menu?.subs?.length - 1
                        ? 'menu-item-subs-menu end'
                        : 'menu-item-subs-menu'
                    }
                    
                  >
                    <span className="linetree" />
                    <Link to={subMenu?.to}>{subMenu?.label}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item
                className="menu-item"
                key={menu?.to?.split('/').slice(2, 4).join('/')}
                icon={icons(menu?.icon)}
              >
                <Link to={menu?.to}>{menu?.label}</Link>
              </Menu.Item>
            )}
          </React.Fragment>
        );
      })}
      <Divider />
      {secMenus.map((secMenu, index) => {
        return (
          <SubMenu
            className="custom-subs-menu"
            key={secMenu?.to?.split('/').slice(2, 4).join('/')}
            title={secMenu?.label}
            icon={icons(secMenu?.icon)}
          >
            {secMenu?.subs?.map((subMenu: IMenu, index: number) => (
              <Menu.Item
                key={subMenu?.to?.split('/').slice(2, 4).join('/')}
                className={
                  index === secMenu?.subs?.length - 1
                    ? 'menu-item-subs-menu end'
                    : 'menu-item-subs-menu'
                }
              >
                <span className="linetree" />
                <Link to={subMenu?.to}>{subMenu?.label}</Link>
              </Menu.Item>
            ))}
          </SubMenu>
        );
      })}
    </Menu>
  );
};
