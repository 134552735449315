import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  GuardRoute,
  ProvideAuth,
} from '@clodeo-internal-finance/components/guard-route/guard-route.component';
import { LastLocationProvider } from 'react-router-last-location';
const LoginComponent = React.lazy(
  () => import('./features/login/login.component'),
);
const LogoutComponent = React.lazy(
  () => import('./features/logout/logout.component'),
);
const NotFoundComponent = React.lazy(
  () => import('./features/not-found/not-found.component'),
);
const ForbiddenAccessComponent = React.lazy(
  () => import('./features/forbidden-access/forbidden-access.component'),
);
const WelcomeComponent = React.lazy(
  () => import('./features/welcome/welcome.component'),
);

import { MainRouter } from './features/main/main-router';

export function AppRouter() {
  return (
    <ProvideAuth>
      <Router>
        <LastLocationProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <GuardRoute path="/features" component={MainRouter} />
              <GuardRoute path="/" exact></GuardRoute>
              <Route path="/login" exact component={LoginComponent} />
              <Route path="/welcome" exact component={WelcomeComponent} />
              <Route path="/logout" exact component={LogoutComponent} />
              <Route path="/404" component={NotFoundComponent} />
              <Route path="/403" component={ForbiddenAccessComponent} />
              <Route path="*">
                <Redirect to="/404" />
              </Route>
            </Switch>
          </Suspense>
        </LastLocationProvider>
      </Router>
    </ProvideAuth>
  );
}
