import React, { useEffect, useState } from 'react';

import { Layout, Row, Col, Space, Divider, Dropdown, Avatar, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { AuthenticationService } from '@clodeo-internal-finance/core/service/auth/authentication.service';

import './headers-layout.component.scss';
import { useSelector } from 'react-redux';
import { Icon } from '@clodeo/clodeo-ui/components/foundations/icon/icon.component';
import { useHistory } from 'react-router-dom';
import { startCase } from 'lodash';

export const HeadersLayoutComponent = () => {
  const { Header } = Layout;

  const title = useSelector((state: any) => state?.entryRelatedData?.title);
  const authService: AuthenticationService = new AuthenticationService();
  const router = useHistory();
  const platformName = JSON.parse(localStorage.getItem('role'));

  const menu = (
    <Menu className='custom-dropdown-menus'>
      <Menu.Item onClick={() => router.push('/welcome')}>Back to Onboarding</Menu.Item>
      <Menu.Item onClick={() => onLogout()} className='text-danger'>Logout</Menu.Item>
    </Menu>
  );

  function onLogout() {
    authService.logout();
  }

  return (
    <Header className="header-wrapper">
      <Row align="middle" justify="space-between">
        <Col>
          <Space size={20} direction="horizontal" align="center">
            <label className="title">{title}</label>

          </Space>
        </Col>
        <Col>
          <Space size={20} direction="horizontal" align="center">
            <Dropdown className='custom-dropdown' overlay={menu} trigger={['click']}>
              <a className="username" onClick={(e) => e.preventDefault()}>
                <span className="app-name">{`${startCase(platformName)} ${platformName === 'Clodeo' || platformName === 'Shipdeo' ? 'Finance' : ''}`} <DownOutlined rev='' className='ml-2' /></span>
              </a>
            </Dropdown>
            <Divider type="vertical" className="title-divider" />
            <span className="name">
              {authService?.user?.user?.fullName}
            </span>
          </Space>
        </Col>
      </Row>
    </Header>
  );
};
