import { Menu as BaseMenu } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { SubMenuProps } from 'antd/lib/menu/SubMenu';
import React from 'react';

const { Item, Divider, SubMenu, ItemGroup } = BaseMenu;
const Menu = (props: MenuProps) => (
  <BaseMenu
    className={'menu-deo ' + (props.className || '')}
    {
    ...props
    }>
  </BaseMenu>
);

export {
  Menu, Item, SubMenu, Divider, ItemGroup
}