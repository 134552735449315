import { environment } from '../../../../../../apps/cod-web/src/environments/environment';
import { RequestService } from '../../rest/request.service';
import { IEntryRelatedData } from './entry-related-data';

export class EntryRelatedDataRestService {
  requestService = new RequestService();
  request: RequestService;
  constructor() {
    this.request = this.requestService.new(`${environment.ENDPOINTS.API_MAINV1}/entry-related-data-awb`);
  }

  getTransaction() {
    return this.request.get<IEntryRelatedData>('transaction');
  }

}
