import {
  castArray,
  uniq,
  concat,
  get,
  identity,
  includes,
  map,
  filter,
} from 'lodash';
import { MenuService } from '@clodeo-internal-finance/components/layout-component/menu/menu.service';
import { AuthenticationService } from './authentication.service';
import { IMenu } from '@clodeo-internal-finance/components/layout-component/menu/menu';

const authService: AuthenticationService = new AuthenticationService();
export class AclService {
  roles = [];
  hasRole(role) {
    return this.roles.includes(role);
  }

  attachRole(role) {
    const roles = castArray(role);
    this.roles = uniq(concat(this.roles, roles));
  }

  can(
    module:
      | 'customer-service'
      | 'finance-apps-clodeo'
      | 'finance-apps-shipdeo'
      | 'setting'
      | 'master-data'
      | string[],
    ...accesses
  ) {
    const user = authService.user as any;
    const roleNames = get(user.roleNames, module);
    // return true;
    if (roleNames && roleNames.includes('super')) {
      return true;
    }

    // if (
    //   user.roleNames['finance-apps-clodeo'].includes('super') ||
    //   user.roleNames['finance-apps-shipdeo'].includes('super') ||
    //   user.roleNames['customer-service'].includes('super') ||
    //   user.roleNames['master-data'].includes('super') ||
    //   user.roleNames['setting'].includes('super')
    // ) {
    //   return true
    // }

    this.roles = get(user?.userAccessPermissionKeys, module);

    accesses = accesses.filter(identity);
    if (!accesses.length) {
      return true;
    }

    return (
      map(accesses, (accessGroup) => this.canByGroup(accessGroup)).filter(
        identity,
      ).length > 0
    );
  }

  flushRoles() {
    this.roles = [];
  }

  canByGroup(access) {
    const abilities = this.roles;
    const accesses = castArray(access);

    const results = accesses.map((ability) => {
      return includes(abilities, ability);
    });

    const matchAbilities = results.filter(identity).length;
    // old condition
    // return matchAbilities === accesses.length;
    return matchAbilities;
  }

  allowedMenus(data = MenuService.headerMenus as any): IMenu[] {
    // const validMenus = data;
    const module = [localStorage.getItem('appName')];
    const validMenus = [];
    filter(data, (menu) => {
      if (this.can(module, menu.permissions)) {
        if (menu.subs) {
          const validSubs = this.allowedMenus(menu.subs);
          menu.subs = validSubs;
          menu.to = menu?.subs[0]?.to || '/'; // FIXME: NavLink can't null or undifind
        }
        validMenus.push(menu);
      }
    });

    return validMenus;
  }

  allowedMenuByModule() {
    const appName = localStorage.getItem('appName');
    const validMenus = this.allowedMenus();

    const allowedMenu = filter(validMenus, (menu) => {
      if (appName === 'finance-apps-clodeo') {
        return menu.id !== 'sales-web' && menu.id !== 'customer-service-web' && menu.id !== 'return-management';
      } else if (appName === 'finance-apps-shipdeo') {
        return menu.id !== 'sales-web' && menu.id !== 'customer-service-web' && menu.id !== 'return-management';
      } else if (appName === 'master-data') {
        return menu.id === 'sales-web';
      } else if (appName === 'customer-service') {
        return menu.id !== 'sales-web' && menu.id !== 'setting' && menu.id !== 'monitoring-awb-general' && menu.id !== 'adjustment' && menu.id !== 'billing' && menu.id !== 'cod-claim' && menu.id !== 'setting' && menu.id !== 'invoice-payment';
      } else if (appName === 'setting') {
        return menu.id === 'parent-setting';
      } else {
        return null;
      }
    });

    return allowedMenu;
  }

  redirectAllowedMenu(history) {
    const validMenus = this.allowedMenuByModule();

    if (validMenus[0]) {
      if (validMenus[0].subs) {
        history.push(validMenus[0]?.subs[0]?.to);
      } else {
        history.push(validMenus[0]?.to);
      }
    } else {
      history.push('/');
    }
  }
}
