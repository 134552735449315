import { Layout } from 'antd';
import { split, find, filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { AclService } from '@clodeo-internal-finance/core/service/auth/acl.service';
import { HeaderLayoutComponent } from '../header/header-layout.component';
import { MenuComponent } from '../menu/menu-layout.component';
import { SiderLayoutComponent } from '../sider/sider-layout.component';
import { useLocation, useHistory } from 'react-router-dom';

// import { useAppSelector, useAppDispatch } from '../../../core/redux/hook';
// import { loadEntryRelatedData } from '../../../core/commons/entry-related-data/entry-related-data.slice';

// import moment from 'moment';

// import { catchError, finalize, tap } from 'rxjs/operators';
// import { Observable, throwError } from 'rxjs';

// import { Form } from 'antd';
// import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
// import { Checkbox } from '@clodeo/clodeo-ui/components/data-entry/checkbox/checkbox.component';
// import { Dropdown } from '@clodeo/clodeo-ui/components/navigation/dropdown/dropdown.component';
// import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
// import { Menu, Item } from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
// import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
// import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
// import { Table } from '@clodeo/clodeo-ui/components/data-display/table/table.component';
// import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
// import { Badge } from '@clodeo/clodeo-ui/components/data-display/badge/badge.component';

import './main-layout.component.scss';
import { IMenu } from '../menu/menu';
import { SidebarLayoutComponent } from '../sidebar/sidebar-layout.component';
// const { Content } = Layout;
const aclService: AclService = new AclService();
import { Menu, Breadcrumb } from 'antd';
import { HeadersLayoutComponent } from '../headers/headers-layout.component';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export function MainLayoutComponent(props: { children? }) {
  // const transactionEntryRd = useAppSelector((state) => state.entryRelatedData.Transaction);
  const [menus, setMenus] = useState<IMenu[]>([]);
  const [collapseSider, setCollapseSider] = useState<boolean>();
  const [parentMenu, setParentMenu] = useState<IMenu>();
  const [compact, setCompact] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const router = {
    history: useHistory(),
  };
  const location = useLocation();

  useEffect(() => {
    if (menus && location.pathname) {
      const parentPath = split(location.pathname, '/');
      const menu = find(menus, function (m) {
        return m.id === parentPath[2];
      });
      setParentMenu(menu);
    }
  }, [menus, location.pathname]);

  useEffect(() => {
    const validMenus = filter(aclService.allowedMenus(), function (o) {
      return !o.isSecMenu;
    });
    setMenus(validMenus);
  }, []);

  const Logo = () => {
    return (
      <div className="p-3 sidebar-header-logo" style={{ marginTop: -5 }}>
        <img
          src="https://nx-design-system-web-development.clodeo.com/img/logo/clodeo/regular.svg"
          alt=""
          srcSet=""
          className="mr-1"
        />
      </div>
    );
  };

  // function ChildrenComponent() {
  //   let component: any;
  //     component = React.Children.map(props.children, child => {
  //       // checking isValidElement is the safe way and avoids a typescript error too
  //       if (React.isValidElement(child)) {
  //         return React.cloneElement(child, {
  //           _: _,
  //           clodeoUI: {
  //             Form,
  //             Button,
  //             Checkbox,
  //             Dropdown,
  //             Input,
  //             Menu,
  //             Item,
  //             Notifications,
  //             Spinner,
  //             Table,
  //             LoadingComponent,
  //             Badge
  //           },
  //           Service: {
  //             handle: new HandleService,
  //             format: new FormatService,
  //           }
  //         } as any);
  //       }
  //       return child;
  //     });
  //   return component[0];
  // }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SidebarLayoutComponent collapsed={(v) => setIsCollapsed(v)} />
      <Layout
        style={{ marginLeft: isCollapsed ? 80 : 200, minHeight: '100vh' }}
      >
        <HeadersLayoutComponent />
        <Content
          style={{
            margin: '5px 12px',
            overflow: 'initial',
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
    // <Layout>
    //   {/* <SiderLayoutComponent
    //     logo={<Logo />}
    //     compact={compact}
    //     onCollapseChanges={setCollapseSider}
    //     menu={
    //       <MenuComponent
    //         onCompactChange={setCompact}
    //         isCollapse={collapseSider}
    //         pathName={location.pathname}
    //         menus={menus}
    //       />
    //     }
    //   /> */}
    //   <SidebarLayoutComponent />
    //   <Layout>
    //     {/* <HeaderLayoutComponent menus={menus} parentMenu={parentMenu} pathName={location.pathname} /> */}
    //     <Content
    //       style={{ margin: '0 16px' }}
    //       // className="main-content px-3 py-1"
    //     >
    //       {props.children}
    //     </Content>
    //   </Layout>
    // </Layout>
  );
}
