

import { RequestService } from '../rest/request.service';
import qs from 'querystring';

export class AuthRestService {
  requestService = new RequestService();
  request: RequestService;
  constructor(baseUrl, withoutInterceptor = false) {
    this.request = this.requestService.new(baseUrl, withoutInterceptor);
  }

  config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  };

  /**
   *
   * @param payload
   * @returns
   */
  getToken(payload) {
    return this.request.post<unknown>('/oauth2/token', qs.stringify(payload), this.config);
  }
}
