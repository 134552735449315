import { AxiosInstance, AxiosResponse, AxiosStatic } from 'axios';
export class HttpExtsrvService {
  axiosInterceptors(axios: AxiosInstance | AxiosStatic) {
    axios.interceptors.response.use(response => {
      this.handleResponse(response);

      return response;
    }, error => {
      this.handleResponse(error.response);

      throw error;
    });
  }

  private handleResponse(response: AxiosResponse) {
    // if (response?.data?.errors?.length) {
    //   this.handleErrors(response);
    // }

  }

  // private handleErrors(response: AxiosResponse) {
  //   if(!response?.config?.headers?.hideErrorNotif) {
  //     const message = this.messageParseService.parse(response.data);
  //     // this.notif.show({
  //     //   type: 'error',
  //     //   title: 'Error',
  //     //   description: ,
  //     //   useService: true
  //     // });
  //   }
  // }
}
