// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  environmentName: 'local',
  production: false,
  ENDPOINTS: {
    API_AUTH: 'https://internal-finance-auth-api-development.clodeo.id',
    API_MAIN: 'https://internal-finance-cod-api-development.clodeo.id',
    API_AUTHV1: 'https://internal-finance-auth-api-development.clodeo.id/v1',
    API_MAINV1: 'https://internal-finance-cod-api-development.clodeo.id/v1',
    API_COD_V1: 'https://internal-finance-cod-api-development.clodeo.id/v1',
    API_BILLING_V1: 'https://internal-finance-billing-api-development.clodeo.id/v1',
    API_SALES_V1: 'https://internal-finance-billing-api-development.clodeo.id/v1',
    API_MAIN_CS: 'https://monitoring-awb-http.core.cs.d.vitamincode.id',
    API_MASTER_DATA_CS: 'https://monitoring-awb-http.core.cs.d.vitamincode.id/master-data'
  },
};
