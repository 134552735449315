import React, { useEffect, useState } from 'react';

import { Layout, Menu } from 'antd';

import './sidebar-layout.component.scss';
import { MenusLayoutComponent } from '../menus/menus-layout.component';

import clodeoImg from '../../../../../apps/cod-web/src/assets/img/layouts/clodeo-logo-new.svg';
import clodeoMini from '../../../../../apps/cod-web/src/assets/img/layouts/clodeo-logo-mini.svg';

export const SidebarLayoutComponent = ({ collapsed }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { Sider } = Layout;
  const { SubMenu } = Menu;

  useEffect(() => {
    collapsed(isCollapsed);
  }, [isCollapsed]);
  return (
    <Sider
      className="custom-sidebar"
      collapsed={isCollapsed}
      collapsible
      onCollapse={() => setIsCollapsed(!isCollapsed)}
      theme="light"
    >
      <div className="wrapper-clodeo-logo">
        <img
          src={isCollapsed ? clodeoMini : clodeoImg}
          alt="clodeo"
          className="clodeo-logo"
        />
        <div className="line" />
      </div>

      <MenusLayoutComponent />
    </Sider>
  );
};
