import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HandleService } from '@clodeo/clodeo-core/handle/handle.service';
import { EntryRelatedDataRestService } from './entry-related-data-rest.service';
import { IEntryRelatedData } from './entry-related-data';

const entryRelatedDataRestService: EntryRelatedDataRestService = new EntryRelatedDataRestService();

const initialState: IEntryRelatedData = {
  Transaction: null,
  title: null,
};

const { handleRequest } = new HandleService();

export const fetchDataTransaction = createAsyncThunk(
  'entryRelatedData/fetchTransactionData',
  async (userId, thunkAPI) => {
    await handleRequest({
      obs: entryRelatedDataRestService.getTransaction(),
      onDone: (response) => {
        thunkAPI.dispatch(
          entryRelatedDataSlice.actions.setEntryRelatedData({
            type: 'Transaction',
            value: response.data,
          }),
        );
      },
    });
    // return response;
  },
);

export const entryRelatedDataSlice = createSlice({
  name: 'entryRelatedData',
  initialState,
  reducers: {
    setEntryRelatedData: (
      state,
      { payload }: { type: string; payload: { type: string; value: any } },
    ) => {
      state[payload.type] = payload.value;
    },
    loadEntryRelatedData: (
      state,
      { payload }: { type: string; payload: { type: string } },
    ) => {
      switch (payload.type) {
        case 'Transaction':
          fetchDataTransaction();
          break;

        default:
          break;
      }
    },
    setTitle: (state, action) => {
      state.title = action.payload === '-' ? '' : action.payload;
    },
  },
});

export const { loadEntryRelatedData, setTitle } = entryRelatedDataSlice.actions;

export default entryRelatedDataSlice.reducer;
