import { IMenu } from './menu';

export class MenuService {
  static get headerMenus(): IMenu[] {
    return [
      {
        id: 'monitoring-awb-general',
        label: 'General Monitoring Resi',
        permissions: [
          'monitoring-awb.monitoring-awb.view',
          'monitoring-cs.monitoring-awb.view',
        ],
        module: [
          'finance-apps-clodeo' || 'finance-apps-shipdeo' || 'customer-service',
        ],
        to: '/features/monitoring-awb',
        icon: 'eye',
      },

      // Tab Menu Adjustment
      {
        id: 'adjustment',
        label: 'Adjustment',
        permissions: [
          'adjustment.adjustment.view',
          'adjustment.debit-note.view',
          'adjustment.credit-note.view',
        ],
        module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
        dropdown: true,
        parent: true,
        to: '/features/adjusment',
        icon: 'chat',
        subs: [
          {
            id: 'adjustment-awb',
            label: 'Adjustment List',
            permissions: [
              'adjustment.debit-note.view',
              'adjustment.credit-note.view',
              'adjustment.adjustment.view',
            ],
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/adjustment/awb/debit-notes',
          },
          {
            id: 'adjustment-invoices',
            label: 'Debit Note',
            permissions: ['adjustment.debit-note.view'],
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/adjustment/invoices/active',
          },
          {
            id: 'adjustment-credit-notes',
            label: 'Credit Note',
            permissions: ['adjustment.credit-note.view'],
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/adjustment/credit-notes/active',
          },
        ],
      },
      {
        id: 'billing',
        label: 'Billing',
        permissions: [
          'billing.monitoring-awb.view',
          'billing.proforma.view',
          'billing.invoice.view',
          'billing.awb-not-found.view',
          'billing.deleted-awb.view',
        ],
        module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
        dropdown: true,
        parent: true,
        to: '/billing',
        icon: 'circles-four',
        subs: [
          {
            id: 'monitoring-awb-billing',
            label: 'Monitoring Resi',
            permissions: ['billing.monitoring-awb.view'],
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/billing/monitoring/awb',
          },
          {
            id: 'proforma-invoice',
            label: 'Proforma Invoice',
            permissions: ['billing.proforma.view'],
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/billing/proforma-invoice/active',
          },
          {
            id: 'invoice',
            label: 'Invoice',
            permissions: ['billing.invoice.view'],
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/billing/invoice/active',
          },
          // {
          //   id: 'awb-not-found-billing',
          //   label: 'Resi Tidak Ditemukan',
          //   permissions: ['billing.awb-not-found.view'],
          //   module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
          //   to: '/features/billing/awb-not-found',
          // },
          // {
          //   id: 'receipts-deleted-billing',
          //   label: 'Resi Dihapus',
          //   module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
          //   permissions: ['billing.deleted-awb.view'],
          //   to: '/features/billing/receipts-deleted/active',
          //   subs: [
          //     {
          //       id: 'receipts-deleted-active',
          //       permissions: ['billing.deleted-awb.view'],
          //       module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
          //       label: 'Resi Aktif',
          //       to: '/features/billing/receipts-deleted/active',
          //     },
          //     {
          //       id: 'receipts-deleted-not-found',
          //       module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
          //       permissions: ['billing.deleted-awb.view'],
          //       label: 'Resi Tidak Ditemukan',
          //       to: '/features/billing/receipts-deleted/not-found',
          //     },
          //   ],
          // },
        ],
      },

      // Tab Menu COD
      {
        id: 'cod-claim',
        label: 'COD Claim',
        permissions: [
          'cod.monitoring-awb.view',
          'cod.awb-ready-to-cashout.view',
          'cod.approved-disbursement.view',
          'cod.awb-not-found.view',
          'cod.deleted-awb.view',
          'cod.balance.view'
        ],
        module: ['finance-apps-clodeo'],
        to: '/features/cod/monitoring-awb',
        dropdown: true,
        parent: true,
        icon: 'file-text',
        subs: [
          {
            id: 'monitoring-awb',
            label: 'Monitoring Resi',
            permissions: ['cod.monitoring-awb.view'],
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/cod/monitoring-awb',
          },
          {
            id: 'receipts-ready-to-be-cashed',
            label: 'Resi Siap Dicairkan',
            permissions: ['cod.awb-ready-to-cashout.view'],
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/cod/receipts-ready-to-be-cashed',
          },
          {
            id: 'disbursement-approved',
            permissions: ['cod.approved-disbursement.view'],
            label: 'Pencairan Disetujui',
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/cod/disbursement-approved',
            subs: [
              {
                id: 'list-grouped',
                permissions: ['cod.approved-disbursement.view'],
                module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
                label: 'Pencairan Disetujui',
                to: '/features/cod/disbursement-approved',
              },
              {
                id: 'list-recipt',
                module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
                permissions: ['cod.approved-disbursement.view'],
                label: 'Resi Pencairan Disetujui',
                to: '/features/cod/disbursement-approved/list-receipt',
              },
              {
                id: 'list-return-recipt',
                module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
                permissions: ['cod.approved-disbursement.view'],
                label: 'Resi Retur',
                to: '/features/cod/disbursement-approved/list-return-receipt',
              },
            ],
          },
          {
            id: 'balance',
            label: 'Balance',
            permissions: ['cod.balance.view'],
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/cod/balance',
          },
          // {
          //   id: 'awb-not-found',
          //   permissions: ['cod.awb-not-found.view'],
          //   module: ['finance-apps-clodeo'],
          //   label: 'Resi Tidak Ditemukan',
          //   to: '/features/cod/awb-not-found',
          // },
          // {
          //   id: 'receipts-deleted',
          //   label: 'Resi Dihapus',
          //   module: ['finance-apps-clodeo'],
          //   permissions: ['cod.deleted-awb.view'],
          //   to: '/features/cod/receipts-deleted/active',
          //   subs: [
          //     {
          //       id: 'receipts-deleted-active',
          //       permissions: ['cod.deleted-awb.view'],
          //       module: ['finance-apps-clodeo'],
          //       label: 'Resi Aktif',
          //       to: '/features/cod/receipts-deleted/active',
          //     },
          //     {
          //       id: 'receipts-deleted-not-found',
          //       module: ['finance-apps-clodeo'],
          //       permissions: ['cod.deleted-awb.view'],
          //       label: 'Resi Tidak Ditemukan',
          //       to: '/features/cod/receipts-deleted/not-found',
          //     },
          //   ],
          // },
        ],
      },

      // Sales Web / Master Data Tab menu
      {
        id: 'sales-web',
        label: 'Master Data',
        permissions: [
          'master-data.customer.view',
          'master-data.sales.view',
          'master-data.sales-category.view',
          'master-data.sales-company.view',
          'master-data.customer-category.view',
          'master-data.sales-target.view',
          'master-data.customer-verification.view',
          'master-data.approval-discount-history.view',
          'master-data.approval-cod-fee-history.view',
          'master-data.sales-commission.view',
        ],
        module: ['master-data'],
        to: '/',
        parent: true,
        dropdown: true,
        icon: 'circle-half',
        subs: [
          {
            id: 'customer',
            label: 'Customer',
            permissions: ['master-data.customer.view'],
            module: ['master-data'],
            to: '/features/sales/customer',
          },
          {
            id: 'customer-category',
            label: 'Customer Category',
            permissions: ['master-data.customer-category.view'],
            module: ['master-data'],
            to: '/features/sales/customer-category',
          },
          {
            id: 'sales',
            label: 'Sales',
            permissions: ['master-data.sales.view'],
            module: ['master-data'],
            to: '/features/sales/sales',
          },
          {
            id: 'sales-category',
            label: 'Sales Category',
            permissions: ['master-data.sales-category.view'],
            module: ['master-data'],
            to: '/features/sales/sales-category',
          },
          {
            id: 'sales-company',
            label: 'Sales Company',
            permissions: ['master-data.sales-company.view'],
            module: ['master-data'],
            to: '/features/sales/sales-company',
          },
          {
            id: 'sales-target',
            label: 'Sales Target',
            permissions: ['master-data.sales-target.view'],
            module: ['master-data'],
            to: '/features/sales/sales-target',
          },
          {
            id: 'sales-commission',
            label: 'Sales Commission',
            permissions: ['master-data.sales-commission.view'],
            module: ['master-data'],
            to: '/features/sales/sales-commission/list',
          },
          // {
          //   id: 'setting',
          //   label: 'Setting',
          //   permissions: ['setting.view'],
          //   module: ['master-data'],
          //   to: '/features/sales/setting',
          // },
          {
            id: 'bank-account-verification',
            label: 'Verifikasi Customer',
            permissions: [
              'master-data.customer-verification.create',
              'master-data.customer-verification.view',
              'master-data.customer-verification.edit',
              'master-data.customer-verification.delete',
            ],
            module: ['master-data'],
            to: '/features/sales/bank-account-verification',
          },
          {
            id: 'history-discount',
            label: 'Riwayat Perubahan Diskon',
            permissions: ['master-data.approval-discount-history.view'],
            module: ['master-data'],
            to: '/features/sales/history-discount',
          },
          {
            id: 'history-cod-fee',
            label: 'History COD Fee',
            permissions: ['master-data.approval-cod-fee-history.view'],
            module: ['master-data'],
            to: '/features/sales/history-cod-fee',
          },
        ],
      },

      // CS Web Menus
      {
        id: 'customer-service-web',
        label: 'CS Monitoring',
        permissions: [
          'monitoring-cs.monitoring-awb.view',
          'monitoring-cs.follow-up.view',
          'monitoring-cs.problem-awb.view',
          'monitoring-cs.cancel-request.view',
          'monitoring-cs.awb-return.view',
          'monitoring-cs.awb-lost-broken.view',
        ],
        module: ['customer-service'],
        to: '/',
        parent: true,
        dropdown: true,
        icon: 'chat-dots',
        subs: [
          {
            id: 'receipt-monitoring-list',
            label: 'Monitoring Resi',
            permissions: ['monitoring-cs.monitoring-awb.view'],
            module: ['customer-service'],
            to: '/features/customer-service/receipt-monitoring-list',
          },
          {
            id: 'awb-management',
            label: 'Airwaybill Managment',
            permissions: [
              'monitoring-cs.awb-return.view',
              'monitoring-cs.awb-lost-broken.view',
            ],
            module: ['customer-service'],
            to: '/features/customer-service/awb-management/lost-broken/list',
          },
          {
            id: 'follow-up-list',
            label: 'Follow Up',
            permissions: ['monitoring-cs.follow-up.view'],
            module: ['customer-service'],
            to: '/features/customer-service/follow-up/pickup/list',
          },
          {
            id: 'cancel-request-list',
            label: 'Permintaan Pembatalan',
            permissions: ['monitoring-cs.cancel-request.view'],
            module: ['customer-service'],
            to: '/features/customer-service/cancel-request/list',
          },
          {
            id: 'receipt-problem',
            label: 'Resi Problem',
            permissions: ['monitoring-cs.problem-awb.view'],
            module: ['customer-service'],
            to: '/features/customer-service/receipt-problem/list',
          },
        ],
      },
      {
        id: 'return-management',
        label: 'Return Management',
        permissions: [
          'monitoring-cs.monitoring-awb.view',
          'monitoring-cs.follow-up.view',
          'monitoring-cs.problem-awb.view',
          'monitoring-cs.cancel-request.view',
          'monitoring-cs.awb-return.view',
          'monitoring-cs.awb-lost-broken.view',
        ],
        module: ['customer-service'],
        to: '/features/return-management',
        parent: true,
        dropdown: true,
        icon: 'gear',
        subs: [
          {
            id: 'potential-returns',
            label: 'Potensial Return',
            permissions: ['monitoring-cs.monitoring-awb.view'],
            module: ['customer-service'],
            to: '/features/return-management/potential-returns/list',
          },
          {
            id: 'return-shipping',
            label: 'Pengiriman Retur',
            permissions: ['monitoring-cs.monitoring-awb.view'],
            module: ['customer-service'],
            to: '/features/return-management/return-shipping/list',
          },
          {
            id: 'returns-accepted',
            label: 'Retur Diterima',
            permissions: ['monitoring-cs.monitoring-awb.view'],
            module: ['customer-service'],
            to: '/features/return-management/returns-accepted/list',
          }
        ],
      },

      // payment menus
      {
        id: 'invoice-payment',
        label: 'Pembayaran',
        permissions: ['payment.invoice-payment.view', 'payment.payment.view'],
        module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
        to: '/features/invoice-payment',
        icon: 'money',
        subs: [
          {
            id: 'invoice-payment',
            permissions: ['payment.invoice-payment.view'],
            label: 'Pembayaran',
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/invoice-payment',
          },
          {
            id: 'history-payment',
            permissions: ['payment.payment.view'],
            label: 'History Pembayaran',
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/payment-history',
          },
        ],
      },
      {
        id: 'subscription',
        label: 'Subscription',
        permissions: ['payment.invoice-payment.view', 'payment.payment.view'],
        module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
        to: '/',
        icon: 'money',
        parent: true,
        subs: [
          {
            id: 'subscription-settings',
            permissions: ['payment.invoice-payment.view'],
            label: 'Setting',
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/subscription/setting/list',
          },
          {
            id: 'subscription-seller',
            permissions: ['payment.invoice-payment.view'],
            label: 'Seller',
            module: ['finance-apps-clodeo' || 'finance-apps-shipdeo'],
            to: '/features/subscription/seller/list',
          },
        ],
      },

      // setting menus
      {
        id: 'parent-setting',
        label: 'Pengaturan',
        permissions: [
          'setting.setting.clodeo.view',
          'setting.setting.shipdeo.view',
          'setting.user.view',
          'setting.role.view',
          'setting.permission.view',
          'setting.approval-discount.view',
          'setting.approval-cod-fee.view',
          'setting.sla-return-profile-configuration.view'
        ],
        module: ['setting'],
        to: '/features/setting-clodeo',
        // secMenuPosition: 3,
        isSecMenu: true,
        parent: true,
        dropdown: true,
        icon: 'gear',
        subs: [
          {
            id: 'clodeo',
            permissions: ['setting.setting.clodeo.view'],
            module: ['setting'],
            label: 'Clodeo',
            to: '/features/setting-clodeo',
          },
          {
            id: 'shipdeo',
            permissions: ['setting.setting.shipdeo.view'],
            module: ['setting'],
            label: 'Shipdeo',
            to: '/features/setting-shipdeo',
          },
          {
            id: 'users',
            permissions: ['setting.user.view'],
            module: ['setting'],
            label: 'Users',
            to: '/features/users',
          },
          {
            id: 'roles',
            permissions: ['setting.role.view'],
            module: ['setting'],
            label: 'Roles',
            to: '/features/users/access-roles',
          },
          {
            id: 'permissions',
            permissions: ['setting.permission.view'],
            module: ['setting'],
            label: 'Permissions',
            to: '/features/users/permissions',
          },
          {
            id: 'diskon',
            permissions: ['setting.approval-discount.view'],
            module: ['setting'],
            label: 'Perubahan Diskon',
            to: '/features/perubahan-diskon',
          },
          {
            id: 'amendment-cod-fee',
            permissions: ['setting.approval-cod-fee.view'],
            module: ['setting'],
            label: 'Perubahan COD Fee',
            to: '/features/amendment-cod-fee',
          },
          {
            id: 'profile-configuration',
            permissions: ['setting.sla-return-profile-configuration.view'],
            module: ['setting'],
            label: 'Konfigurasi SLA Retur',
            to: '/features/profile-configuration/list',
          },
        ],
      },

      // {
      //   id: 'parent-users',
      //   secMenuPosition: 4,
      //   isSecMenu: true,
      //   to: '/features/users',
      //   label: 'Pengguna',
      //   permissions: ['setting.user-management.view'],
      //   module: ['setting' || 'finance-apps-shipdeo' || 'finance-apps-shipdeo'],
      //   subs: [
      //     {
      //       id: 'users',
      //       permissions: ['setting.user-management.view'],
      //       module: ['setting' || 'finance-apps-shipdeo' || 'finance-apps-shipdeo'],
      //       label: 'Users',
      //       to: '/features/users',
      //     },
      //     {
      //       id: 'roles',
      //       permissions: ['setting.user-management.view'],
      //       module: ['setting' || 'finance-apps-shipdeo' || 'finance-apps-shipdeo'],
      //       label: 'Roles',
      //       to: '/features/users/access-roles',
      //     },
      //     {
      //       id: 'permissions',
      //       module: ['setting' || 'finance-apps-shipdeo' || 'finance-apps-shipdeo'],
      //       permissions: ['setting.user-management.view'],
      //       label: 'Permissions',
      //       to: '/features/users/permissions',
      //     },
      //   ],
      // },

      // {
      //   id: 'diskon',
      //   label: 'Perubahan Diskon',
      //   isSecMenu: true,
      //   secMenuPosition: 4,
      //   permissions: ['setting.approval-discount.view'],
      //   module: ['setting' || 'finance-apps-shipdeo' || 'finance-apps-shipdeo'],
      //   to: '/features/perubahan-diskon',
      // },
      // {
      //   id: 'amendment-cod-fee',
      //   label: 'Perubahan COD Fee',
      //   isSecMenu: true,
      //   secMenuPosition: 4,
      //   permissions: ['setting.approval-cod-fee.view'],
      //   module: ['setting' || 'finance-apps-shipdeo' || 'finance-apps-shipdeo'],
      //   to: '/features/amendment-cod-fee',
      //   // disabled: true
      // },
      // {
      //   id: 'diskon',
      //   label: 'Perubahan Diskon',
      //   isSecMenu: true,
      //   secMenuPosition: 4,
      //   permissions: ['approval-discount.view'],
      //   module: 'general',
      //   to: '/features/perubahan-diskon',
      //   // disabled: true
      // },
      // {
      //   id: 'amendment-cod-fee',
      //   label: 'Perubahan COD Fee',
      //   isSecMenu: true,
      //   secMenuPosition: 4,
      //   permissions: ['approval-cod-fee.view'],
      //   module: 'general',
      //   to: '/features/amendment-cod-fee',
      //   // disabled: true
      // },
      // Portal Referral
      {
        id: 'portal-admin-referral-web',
        label: 'Portal Referral',
        permissions: ['monitoring-cs.view'],
        module: ['customer-service'],
        dropdown: true,
        parent: true,
        to: '/features/portal-referral',
        icon: 'chat',
        subs: [
          {
            id: 'request-verification-data-list',
            label: 'Request Verification',
            permissions: ['monitoring-cs.view'],
            module: ['customer-service'],
            to: '/features/portal-referral/data-verification/list',
          },
          {
            id: 'payment-comission-referral',
            label: 'Pembayaran Komisi Referral',
            permissions: ['monitoring-cs.view'],
            module: ['customer-service'],
            to: '/features/portal-referral/referral-payment-comission/list',
          }
        ],
      },
    ];
  }
}
