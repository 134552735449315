import React from 'react';

import { Switch, useRouteMatch } from 'react-router-dom';
import { GuardRoute } from '@clodeo-internal-finance/components/guard-route/guard-route.component';

const ReceiptMonitoringListComponent = React.lazy(
  () => import('./app/features/receipt-monitoring/list'),
);
const ReceiptMonitoringInvoiceDetailComponent = React.lazy(
  () => import('./app/features/receipt-monitoring/invoice-detail'),
);

const FollowUpListComponent = React.lazy(
  () => import('./app/features/follow-up/list'),
);
const ReturnManagementListComponent = React.lazy(
  () => import('./app/features/follow-up/return-management/list'),
);

const ReceiptProblemListComponent = React.lazy(
  () => import('./app/features/receipt-problem/list'),
);
const ReceiptProblemDetailInvoiceComponent = React.lazy(
  () => import('./app/features/receipt-problem/detail-invoice'),
);

const CancelRequestListComponent = React.lazy(
  () => import('./app/features/cancel-request/list'),
);

const LostBrokenListComponent = React.lazy(
  () => import('./app/features/lost-broken/list'),
);

const ReturnedListComponent = React.lazy(
  () => import('./app/features/returned/list'),
);

const ReturnShippingListComponent = React.lazy(
  () => import('./app/features/return-shipping/list'),
);

export function CsRouter() {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      {/* monitoring-resi */}
      <GuardRoute
        path={`${url}/customer-service/receipt-monitoring-list`}
        exact
        meta={{
          permissions: ['monitoring-cs.monitoring-awb.view'],
          module: ['customer-service'],
        }}
      >
        <ReceiptMonitoringListComponent />
      </GuardRoute>
      <GuardRoute
        path={`${url}/customer-service/receipt-monitoring-list/invoice-detail/:id`}
        exact
        meta={{
          permissions: ['monitoring-cs.monitoring-awb.view'],
          module: ['customer-service'],
        }}
      >
        <ReceiptMonitoringInvoiceDetailComponent />
      </GuardRoute>

      {/* follow-up */}
      <GuardRoute
        path={`${url}/customer-service/follow-up/pickup/list`}
        exact
        meta={{
          permissions: ['monitoring-cs.follow-up.view'],
          module: ['customer-service'],
        }}
      >
        <FollowUpListComponent />
      </GuardRoute>

      {/* cancel-request */}
      <GuardRoute
        path={`${url}/customer-service/cancel-request/list`}
        exact
        meta={{
          permissions: ['monitoring-cs.cancel-request.view'],
          module: ['customer-service'],
        }}
      >
        <CancelRequestListComponent />
      </GuardRoute>

      {/* receipt-problem */}
      <GuardRoute
        path={`${url}/customer-service/receipt-problem/list`}
        exact
        meta={{
          module: ['customer-service'],
          permissions: ['monitoring-cs.problem-awb.view'],
        }}
      >
        <ReceiptProblemListComponent />
      </GuardRoute>
      <GuardRoute
        path={`${url}/customer-service/receipt-problem/detail-invoice/:id`}
        exact
        meta={{
          module: ['customer-service'],
          permissions: ['monitoring-cs.problem-awb.view'],
        }}
      >
        <ReceiptProblemDetailInvoiceComponent />
      </GuardRoute>

      {/* lost-broken */}
      <GuardRoute
        path={`${url}/customer-service/awb-management/lost-broken/list`}
        exact
        meta={{
          permissions: ['monitoring-cs.awb-lost-broken.view'],
          module: ['customer-service'],
        }}
      >
        <LostBrokenListComponent />
      </GuardRoute>

      {/* potential-returns */}
      <GuardRoute
        path={`${url}/return-management/potential-returns/list`}
        exact
        meta={{
          permissions: ['monitoring-cs.follow-up.view'],
          module: ['customer-service'],
        }}
      >
        <ReturnManagementListComponent />
      </GuardRoute>

      {/* return-shipping */}
      <GuardRoute
        path={`${url}/return-management/return-shipping/list`}
        exact
        meta={{
          permissions: ['monitoring-cs.follow-up.view'],
          module: ['customer-service'],
        }}
      >
        <ReturnShippingListComponent />
      </GuardRoute>

      {/* returns-accepted */}
      <GuardRoute
        path={`${url}/return-management/returns-accepted/list`}
        exact
        meta={{
          permissions: ['monitoring-cs.awb-return.view'],
          module: ['customer-service'],
        }}
      >
        <ReturnedListComponent />
      </GuardRoute>
    </Switch>
  );
}
